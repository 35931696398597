@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  margin: 0;
  font-family: 'Roboto', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blue {
  color: #3D55A5 !important;
}

.text-blue a {
  color: #3D55A5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

input#submitButton1 {
  background: #3D55A5;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: lowercase;
  padding: 4px 95px;
  border-radius: 20px;
  font-size: 18px;
}

.slider {
  background: url(./img/slider1.png) no-repeat 0 0;
  background-size: 100%;
  min-height: 360px;
  position: relative;
}



.slider .gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  bottom: 0;
  min-height: 280px;
}

h1 {
  /* font-size: 2.5rem; */
  color: #fff;
  font-weight: 900 !important;
}

.searchPanel h2 {
  font-size: 2rem !important;
  font-weight: 900 !important;
}

.trade_search h4 {
  color: black;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.slider .searchPanel {
  left: 50%;
  min-width: 500px !important;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider .searchPanel input[type="text"]::placeholder {
  font-weight: 500;
  color: #3C3C3C;
}

.slider .searchPanel input[type="text"] {
  border-radius: 26px;
  background-color: #f9f9f9;
  padding: 25px;
  font-weight: 500;
}

.slider .searchPanel .btnSearch {
  font-size: 15px !important;
  font-weight: 500;
  padding: 10px 15px;
  position: absolute;
  right: 2px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.search_engine .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0 !important;
}

.search_engine .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-segi59 {
  padding: 0 !important;
}

.search_engine svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  z-index: 9999 !important;
  color: #3D55A6 !important;
  font-size: 50px !important;
  width: 30px !important;
  height: 30px !important;
  padding-left: 17px;
}

.posRelative {

  position: relative;
  height: 360px;
}



.shadox_box {
  box-shadow: 0 3px 6px #00000029;
}

.rightSection .searchBox ul {
  padding: 20px 25px;
}

.rightSection .courseTop {
  background-color: #3D55A5;
  padding: 10px 10px;
  color: #fff;
  font-size: 20px;
  line-height: 120%;
  border-radius: 6px 6px 0 0;
  font-weight: 400;
  min-height: 65px;
}

.button {
  background-color: #3D55A5 !important;
  border-radius: 26px !important;
  font-size: 16px !important;
}

.input:focus {
  outline: none;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(213 214 215);
  border: none;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(213 214 215);
  border: none;
}

.svgLogo {
  width: 126px;
  height: auto;
}

header {
  padding: 15px 0;
}

.slider {
  position: relative;
}

select {
  border-radius: 25px;
  margin-bottom: 10px;
  background: #ECECEC;
  padding: 8px 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  margin-bottom: 5px !important;
}

.btnLoadMore {
  width: 160px !important;
  margin: 15px auto !important;
}

.leftsectionset {
  max-height: 100% !important;
 
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}

.bg-lgreys {
  background-color: #ececec !important;
}

.bg-lgrey {
  background-color: #ececec !important;
}

.mt-60px {
  margin-top: 60px !important;
}

h2 {
  font-size: 25px !important;
  font-weight: 500;
}



.rightSection .searchBox ul li h2 {
  font-weight: bold;
  text-align: center;
}

.rightSection .searchBox ul li p {
  margin-bottom: 0;
  margin-top: 0;
  padding: 2px 0
}

.btn-check:focus+.btn,
.btn:focus {

  background-color: var(--bs-btn-hover-bg);
  border-color: unset !important;
  outline: 0;
  box-shadow: unset !important;
}

.rightSection .searchBox ul li h6 {
  font-weight: 500;
  color: #3d55a5;
}

.checked {
  color: orange;
}

.tabs {
  padding: 50px 0;
}

.tabs .tab-content {
  min-height: 300px;
  background: #ECECEC;
  width: 100%;
  height: 100%;
  border-radius: 19px;
  padding: 84px 49px 0px 58px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #3D55A5 !important;
}

.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 0 !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
}

:root {
  --primary: #55a0ff;
  --secondary: #9B9B9B;
  --light: #F5F5F5;
  --dark: #161616;
}

.pcourse {
  color: #FFF;
  font-size: 18px;
  margin: 0;
}

.btn-square1 {
  width: 90px !important;
  height: 25px !important;
  border-radius: 15px;
  background-color: #3d55a5 !important;
  color: #fff !important;
  font-weight: 300 !important;
  padding: 0px !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  padding: 0 !important;
  margin: 10px 0px 0 !important;
  line-height: 24px !important;
}

h1 {
  /* font-size:2.0em!important; */
  font-weight: bold !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** task ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/* notification and profile */
.notifyIcon {
  width: 35px;
  height: 35px;
  background: #3C3C3C;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}

.bellIcon {
  position: relative;
}

.bellIcon::after {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 20px;
  background-color: #FFA845;
  position: absolute;
  top: -3px;
  right: 2px;
}

.afterLogin {
  display: flex;
  gap: 20px;
}

.profileIcon {
  width: 35px;
  height: 35px;
  position: relative;
}

.profileIcon::after {
  content: '\f0d7';
  position: absolute;
  top: 50%;
  right: -14px;
  font-family: 'FontAwesome';
  color: #000;
  transform: translateY(-50%);
}

img.iconP {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* notification and profile End*/
/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  margin-right: 30px;
  font-size: 14px !important;
  padding: 25px 0;
  color: #3d55a5;
  font-weight: 600;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #3d55a5;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.wrappers {
  position: relative;
  max-width: 390px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
  margin: 0 auto;
}

.wrappers h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers form .input-box {
  margin: 8px 0;
  text-align: center;
}

.input-box input:focus,
.input-box input:valid {
  border-color: #00aaa1;
}

.pad {
  padding: 6% !important;
}

.modal-body .form-control {
  margin-bottom: 10px;
}

.formSetup {
  border: 1px solid #ccc;
  margin: 50px;
  border-radius: 10px;
  padding: 50px;
}

.topBanner {
  background: url(./img/banner.png) no-repeat 0 -280px;
  background-size: 100%;
  min-height: 750px;
  padding: 75px 0;
  margin-right:-30px;
}

.topBanner h1 {
  color: #3d55a5;
  font-weight: 600 !important;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }

  .login_btn a {
    max-width: 100px;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }


  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}

a {
  color: var(--bs-link-color);
  text-decoration: none !important;
}

.headerImage {
  background-image: url("./img/trade-schools.jpeg");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImagelog {
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImage1 {
  background-image: url("./img/online_courses.png");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerImage2 {
  background-image: url("./img/What-are-5G-Cell-Towers.png");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 220px;
}

.background {
  padding: 100px 0px 50px;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}

.bannerHeadingjob {
  display: flex !important;
  height: 100% !important;
  align-items: flex-end !important;
}

.bannerHeadingjob h1 {
  color: #fff;
  font-size: 40px;
  margin-top: 0px;
}


.shadow {
  display: flex !important;
  align-items: center !important;
  background: rgba(22, 22, 22, .7) !important;
}

.wrappers {
  position: relative;
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}

.wrappers h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers form .input-box {
  margin: 18px 0;
  text-align: center;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  margin-right: 0;
  font-size: 14px !important;
  padding: 25px 0 10px;
  color: #3d55a5;
  font-weight: 600;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #3d55a5;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.mainHeader {
  padding: 10px 15px !important;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}

.headerImage {
  background-image: url("./img/trade-schools.jpeg");
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background {
  padding: 100px 0px 50px;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}




.shadow {
  display: flex !important;
  align-items: center !important;
  background: rgba(22, 22, 22, .7) !important;
}

.wrappers {
  position: relative;
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}

.wrappers h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers form .input-box {
  margin: 18px 0;
  text-align: center;
}

.input-box input:focus,
.input-box input:valid {
  border-color: #00aaa1;
}

form .policy {
  display: flex;
  align-items: center;
}

form h3 {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  margin-left: 8px;
}

.options {
  cursor: pointer;
  width: 300px;
  min-height: 60px;
  max-height: 300px;
  border-radius: 15px;
  background-color: rgb(250, 250, 250);
  box-shadow: 2px 4px 8px #c5c5c5;
  transition: all 300ms;
}

.options.opened {
  overflow-y: scroll;
}

select.form-control.selectOption {
  margin-bottom: 20px;
  border-radius: 20px;

}

input#submitButton {
  background: #3D55A5;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: uppercase;
  padding: 4px 30px;
  border-radius: 20px;
  font-size: 13px;
}

.form-control:focus {
  color: #000000;
  background-color: #fff;
  border-color: #3D55A5;
  outline: 0;
  box-shadow: 0 0 5px 2px #3d55a5cf;
}

select.form-control.selectOption {
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #ECECEC;
  color: #000;
  font-size: 14px;
}

/* Program Section Css Start */
.programsSection {
  padding: 15px 0px;
  overflow: hidden;
}

.padding45 {
  padding: 45px 0px;
}

.padding25 {
  padding: 25px 0px;
}

button.btn.btn-primary.btnSidebar {
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 15px;
  background-color: #3D55A5;
  border: none;
  font-family: 'Lato';
  margin-top: 10px;
}

.btnFuture {
  padding: 5px 40px !important;
}

.filterBtn {
  background-color: #c1c1c1 !important;
  color: #000 !important;
}

.checkBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type=checkbox] {
  accent-color: #3D55A5;
  width: 17px;
  height: 17px;
}

.checkBox label {
  color: #000;
  font-size: 13px;
}

.radioBoxRow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.radioBtn {
  accent-color: #3D55A5;
  width: 17px;
  height: 17px;
}

.filterBody {
  background: #ECECEC;
  border-radius: 10px;
  padding: 30px;
}

.radioBox {
  margin-top: 20px;
}

.radioBox p,
.priceRange p {
  font-size: 15px;
  font-weight: 100;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  color: #000;
  text-transform: uppercase;
}

.priceRange {
  margin-top: 20px;
}

.radioBoxRow label {
  color: #000;
  font-size: 13px;
}

.stateBox {
  margin-top: 20px;
}

.selectOptionSidebar {
  border-radius: 8px;
  font-size: 14px;
  color: #000;
  background-color: #ffffff !important;
  padding: 6px 15px;
}

.searchProgram {
  margin-bottom: 20px;
}

.sideBarFooter {
  display: flex;
  margin-top: 20px;
  gap: 5px;
}

.btnBSidebar {
  border-radius: 20px !important;
  padding: 5px 20px !important;
  font-size: 12px !important;
  background-color: #3D55A5 !important;
  border: none !important;
}

.RefilterBtn {
  background: none !important;
  border: 1px solid #3D55A5 !important;
  color: #3D55A5 !important;
}

/* Programs Body  Start*/
.programBox {
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px;
}

.programsContentInfo h5 {
  color: #3C3C3C;
  font-size: 15px;
  margin: 0;
}

.programContent {
  padding: 15px;
}

.programTitle h1 {
  font-size: 25px;
}

.programTitle {
  background: #3D55A5;
  padding: 15px;
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
}

.programTitle h1 {
  font-size: 16px;
  margin: 0;
  color: #fff;

}

.programsContentInfo {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.programsContentInfo .fa {
  color: #FFA845;
}

.loadBtn {
  text-align: center;
  margin-top: 30px;
}

.loadBtn button {
  background: #3D55A5;
  padding: 5px 30px;
  border-radius: 19px;
  font-size: 14px;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: uppercase;
}

/* Programs Body  End*/

/* Program Section Css End */

/* Tab Systmes Css Start */
.leftSideBtn p {
  margin: 0;
  font-size: 17px;
}

.plusCercle {
  border: 1px solid #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
}

.tabSystems {
  padding-bottom: 50px;
}

.tabPanel {
  background: #ECECEC;
  width: 100%;
  height: 100%;
  padding: 84px 49px 0px 58px;
}

.tabPanel p {
  color: #3C3C3C;
}

/* Tab Systmes Css End */


@media only screen and (max-width:768px) {
  a.nav-item.nav-link.noti {
    background-color: initial!important;
    border-radius: 50%!important;
    margin: 0!important;
    padding: 7px 11px!important;
    background-color: #3C3C3C !important;
    /* width: 100%; */
    width: 40px;
    height: auto !important;
    color: #fff !important;
}
  .programsSideBar {
    margin-bottom: 20px;
  }

  .commentProfile {
    grid-template-columns: 12% 86% !important;
    grid-column-gap: 2%;
  }

  .loccss {
    margin-bottom: 30px;
  }

  .leftsectionset {
    max-height: 100% !important;
    min-height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;

  }

  .bg-lgreys {
    background-color: #fff !important;
  }


  .quizPagination {
    gap: 6px !important;
  }

  .seachBox {
    z-index: 9999;
  }

  .closeSearch {
    right: 10px !important;
    top: -7px !important;
    font-size: 48px !important;
  }

  .counpro1 p {
    padding: 0px !important;
  }

  .onlinecousectinstu {
    margin-bottom: 30px;
  }

  .bannerHeadingcoun h1 {
    font-size: 40px !important;
  }
}

.price-range-slider {
  width: 100%;
}

.price-range-slider .range-value {
  margin: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}

.price-range-slider .range-value input {
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: initial;
  box-shadow: none;
  border: 1px solid #ced4da;
  margin: 10px 0 10px 0;
  border-radius: 11px;
  padding: 6px 15px;
}

.price-range-slider .range-bar {
  border: none;
  background: #3D55A5;
  height: 5px;
  width: 96%;
  margin-left: 8px;
}

.price-range-slider .range-bar .ui-slider-range {
  background: #3D55A5;
}

.price-range-slider .range-bar .ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #3D55A5;
  border: 2px solid #3D55A5;
  height: 17px;
  width: 17px;
  top: -0.52em;
  cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle+span {
  background: #3D55A5;
}

.ui-state-focus,
.ui-state-active {
  border: 2px solid #3D55A5 !important;
}

/*--- /.price-range-slider ---*/

span.priceBudget {
  color: #000;
  font-size: 13px;
}

div#slider-range {
  margin-top: 20px;
}

/* Programs Body  End*/

/* Program Section Css End */

@media only screen and (max-width:767px) {
  .programsSideBar {
    margin-bottom: 20px;
  }
}

/* Tab Systmes Css Start */
.leftSideBtn {
  background: #3D55A5;
  color: #fff;
  border-radius: 19px;
  padding: 6px 20px;
  display: grid;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;

}

.tabSystems {
  padding-bottom: 50px;
}

.tabPanel {
  background: #ECECEC;
  width: 100%;
  height: 100%;
  padding: 84px 49px 0px 58px;
}

.tabPanel p {
  color: #3C3C3C;
}

/* Tab Systmes Css End */
/* related Section Css End */
.related span {
  color: #1515dda3;
  font-size: 12px;
  font-weight: 500px;
}

.related p {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
}

.related-p {
  padding: 0px;
  margin-top: 25px;
}

.related {
  padding: 20px 10px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-weight: 800;
  font-size: 20px;
}

.swiper-pagination-bullet-active {
  background-color: #3D55A5 !important;
}

.related1 {
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-weight: 800;
  font-size: 20px;
}

.relatedResource {
  padding: 0px 10px;
}

/* related Section Css End */
.call-fixed-button1 {
  width: 36px;
  position: fixed;
  bottom: 0px;
  top: 440px;
  right: 0px;
  z-index: 10000;
  float: right;
  border-radius: 12px 12px 0px 0px;
}

.pulsating {
  box-sizing: border-box;
  height: 166px;
  width: 56px;
  border-radius: 20px;
  background: #f98a00;
  color: #000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: pulsate 1.5s ease infinite;
}


button .inner-pulse {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  height: 45px;
  margin: auto;
  min-width: 45px;
  width: 45px;
  background: transparent;
}

.pulsating p {
  text-align: center;
  padding: 2px 42px 4px 25px;
  font-size: 14px;
  font-weight: bold;
}

.tb-text {
  writing-mode: tb
}

.bt-text {
  writing-mode: tb;
  transform: rotate(-180deg);
}

.test-design h5 {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.test-design p {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.location {
  float: right;
}

a {
  color: var(--bs-link-color);
  text-decoration: auto;
}

.rightSection .searchBox ul li img {
  max-width: 100px;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 0;
  margin: 0 auto;
  min-height: 100%;
  width: 300px;
  height: 80px;
  border: 1px solid #ccc;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.test-design h6::before {
  content: '' !important;
  position: absolute !important;

  height: 2px !important;
  width: 8% !important;
  margin-top: -2% !important;
  background: #d5763e !important;


}

.form-control:focus {
  box-shadow: unset !important;
}

.img-design img {
  border-radius: 20px !important;
}

.img-design h5 {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.img-design p {
  color: #221f1f !important;
  text-align: left;
  margin-top: 50px !important;
}

.img-design1 p {
  color: #221f1f !important;
  text-align: left;

}

.img-design h6::before {
  content: '' !important;
  position: absolute !important;
  left: 13% !important;
  height: 1px !important;
  width: 8% !important;
  margin-top: -2% !important;
  background: #d5763e !important;

  transform: translateX(-50%) !important;
}

.img-design h6 {
  color: #221f1f !important;
  text-align: left;
  font-size: 18px;
  margin-top: 50px !important;
}

/* Footer Css Start */
footer.footerSection {
  padding: 80px 0px 20px 0px;
  background-image: linear-gradient(0deg, #ffffff, #d0d0d0, #d0d0d0);
}

.footerTitle {
  margin-bottom: 27px;
}

.footerTitle h3 {
  font-size: 25px;

  margin: 0;
}

.footerBody ul {
  list-style: none;
  padding: 0;
}

.footerBody ul li {
  padding: 5px 0;
}

.footerBody ul li a {
  color: #000;
  text-decoration: none;
}

.subscirpSection {
  width: 50%;
  margin: 0 auto;
  padding-top: 20px;
}

.inputField {
  width: 100%;
  position: relative;
}

h1.subscribeTitle {
  font-size: 25px;

  margin: 0;
  color: #000;
  margin-bottom: 20px;
}

.inputField input {
  width: 100%;
  border-radius: 22px;
  border: none;
  background: #fff;
  padding: 5px 10px;
}

.inputField input::placeholder {
  color: #000;
  font-style: italic;
}

button.subscribeBtn {
  position: absolute;
  right: 0;
  background: #3D55A5;
  border: none;
  height: 100%;
  border-radius: 22px;
  color: #fff;
  padding: 0px 20px;
  font-size: 14px;
}

.followUs {
  margin: 20px 0px;
}

ul.solialLink {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

a.socialLinkItem {
  color: #000;
  padding: 0px 10px;
  font-size: 20px;
}

ul.tandcLink {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

ul.tandcLink a {
  color: #000;
  text-decoration: none;
  padding: 0px 20px;
}

.copyright p {
  color: #000;
  margin: 0;
  font-size: 14px;
}

.footerBottom {
  background: #ECECEC;
  text-align: center;
  padding-bottom: 30px;
}

/*------Post page design start-----*/
body {

  color: #000;
}

.navbar.sticky-top {
  box-shadow: 0px 0px 5px;
  height: 60px;
}

.newPostContainer input[type="text"] {
  width: 100%;
  border-radius: 34px;
  border: none;
  padding-left: 25px;
  outline: none;
}

.newPostContainer {
  position: relative;
  height: 45px;
  background: #fff;
  border-radius: 30px;
}

.profileIconPost.profileImg1 {
  width: 40px;
}

.profileIconPost,
.postPimg,
.commentUserImg,
.groupIcon {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  overflow: hidden;
}

img.newPostProfileImg,
.commentUserImg img,
.postPimg img,
.groupIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newPostAccessory {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.newPostContainer input[type="text"]::placeholder {
  font-style: italic;
  color: #3C3C3C;
  font-size: 14px;
}

button.postButton {
  background: #3D55A5;
  color: #fff;
  padding: 6px 34px;
  height: 100%;
  border-radius: 33px;
  border: none;
  font-size: 14px;
}

.postBtn .fa-clock-o {
  font-size: 25px;
  color: #616161;
}

.postBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.postSection {
  padding-top: 60px;
}

.postAccessoryIcons ul {
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  padding-left: 60px;
  margin-top: 10px;
}

.postAccessoryIcons ul li {
  color: #3C3C3C;
  padding-right: 14px;
}

.trendingButton button {
  border: none;
  background: #3C3C3C;
  color: #fff;
  padding: 5px 15px;
  border-radius: 27px;
  font-size: 12px;
}

.trendingButton button .fa {
  padding-right: 7px;
}

.trendingBG {
  background-color: #FFA845 !important;
}

.freshBg {
  background-color: #3D55A5 !important;
}

.trendingButton {
  padding: 10px 0px;
}

p.postAuthor {
  margin: 0;
  font-size: 14px;
  color: #3C3C3C;
}

small.postAuthorUsername {
  font-size: 11px;
  color: #3C3C3C;
}

.postProfileLeft {
  display: flex;
  gap: 15px;
}

.postAutorName {
  display: flex;
  flex-direction: column;
}

.postDate li {
  color: #3C3C3C;
  font-size: 11px;
}

.postDate ul {
  padding-left: 25px;
}

.viewShowProfile {
  display: flex;
  justify-content: space-between;
}

.postDownArrow i.fa.fa-caret-down {
  color: #3C3C3C;
  font-size: 23px;
}

.postProfileRight {
  display: flex;
  align-items: center;
}

.viewPostSection {
  background: #fff;
  padding: 20px;
  border-radius: 30px;
  margin-top: 20px;
}

.postImg img {
  width: 100%;
}

.postImg {
  margin: 15px 0px;
}

.postTitle h1 {
  font-size: 20px;
  color: #3C3C3C;
}

.postTitle p {
  color: #3C3C3C;
  font-size: 14px;
}

.postTag {
  border-bottom: 1px solid #3D55A5;
  padding-bottom: 10px;
  color: #3D55A5;
}

.postTag a {
  color: #3D55A5;
  font-size: 13px;
}

.postShareLike {
  display: flex;
  align-items: center;
  gap: 40px;
  color: #3D55A5;
  margin-top: 10px;
}

.postLike,
.postShare {
  font-size: 20px;
}

.space30 {
  height: 30px;
}

.space15 {
  height: 15px;
}

.autorComment p {
  color: #3C3C3C;
  font-size: 14px;
  margin: 0;
}

.autorComment {
  padding-left: 50px;
  margin-top: 10px;
}

.postSidebar {
  margin-top: 20px;
}

.sideBarSearchProgram .wrappers {
  border-radius: 15px;
}

.postSendBtn {
  display: none;
}

.commentProfile {
  display: grid;
  width: 100%;
  grid-template-columns: 4% 94%;
  grid-column-gap: 2%;
  margin-top: 20px;
}

.commentInput {
  background: #ECECEC;
  position: relative;
  border-radius: 26px;
  padding: 0px 17px;
  display: flex;
  align-items: center;
}

.commentInput input[type="text"]::placeholder {
  font-style: italic;
  font-size: 14px;
  margin-top: -4px !important;
}

.commentInput input[type="text"] {
  width: 100%;
  border-radius: 26px;
  background: no-repeat;
  border: none;
  outline: none;
  height: 100%;
}

.commentInput button {
  position: absolute;
  right: 6px;
  border: none;
  background: #3D55A5;
  color: #fff;
  border-radius: 26px;
  padding: 1px 15px;
  font-family: 'Lato';
}

h4.gName {
  font-size: 14px;
  color: #3C3C3C;
  font-weight: 200;
  margin: 0;
}

.divider {
  width: 50%;
  height: 1px;
  background: #3D55A5;
  text-align: center;
  margin: 20px auto;
  margin-top: 30px;
}

.sideBarTitle h1 {
  font-size: 27px;
  font-family: 'Lato';
  text-align: center;
  font-weight: 800;
  color: #3C3C3C;
  margin: 0;
}

.postGroups {
  padding: 28px;
  background: #fff;
  border-radius: 30px;
}

p.groupFollowers {
  color: #3D55A5;
  margin: 0;
  font-size: 14px;
  margin-top: -3px;
}

.groupSection {
  display: grid;
  gap: 6px;
  grid-template-columns: 12% 72% 12%;
  margin: 15px 0px;
}

.groupAdd {
  width: 35px;
  height: 35px;
  border: 2px solid #3D55A5;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}

.gPlus {
  color: #3D55A5;
}

.btnConselling {
  position: fixed;
  top: 60%;
  background-color: #FFA845 !important;
  border-radius: 0 0 5px 5px !important;
  rotate: 90deg;
  border: 0 !important;
  right: -64px;
}

.btnConselling:hover {
  background-color: #f6982c !important;
}

@media only screen and (max-width:767px) {
  .trendingButton button {
    margin-bottom: 5px;
  }

  .postSendBtn {
    display: block;
  }

  .postSendText {
    display: none;
  }

  button.postButton {
    padding: 9px 14px;
  }

  h5.cssh {
    color: #404b71 !important;
    font-size: 18px !important;
    font-weight: 800 !important;
    margin: 5px !important;
    text-align: center !important;
  }

  .newPostContainer input[type="text"]::placeholder {
    font-size: 10px;
  }

  .profileIconPost.profileImg1 {
    width: 55px;
  }
}

/* Course Details Page Css Start */
.courseTitleD {
  color: #fff;
}

.overViewBg {
  background: #ececec;
  padding: 55px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
}

.overViewContent h1 {
  font-size: 25px;
  font-family: 'Lato';
}

.courseDetalsPrice h3 {
  font-size: 25px;
  color: #000;
}

h4.crossMrp {
  color: #3D55A5;
  text-decoration: line-through;
  font-size: 30px;
}

.courseDetalsPrice {
  text-align: center;
}

h1.coursePrice {
  color: #FFA845;
  font-size: 50px;
}

.courseDetalsPrice button {
  background: #3D55A5;
  color: #fff;
  border: none;
  width: 115px;
  height: 40px;
  border-radius: 39px;
}

.eligibilityContnet h1,
.courseSkills h1 {
  font-size: 25px;
  font-family: 'Lato';
  margin-bottom: 20px;
  color: #000;
}

.skillsTag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.skillsTag a {
  background: #ECECEC;
  padding: 5px 15px;
  border-radius: 20px;
  color: #000;
  font-size: 15px;
}

.EligibilityorSkills {
  padding: 30px;
}

.borderOutline {
  border: 3px solid #ECECEC;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.courseOverviewSection {
  padding-bottom: 80px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #3D55A5;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  color: #fff;
  background-color: #3D55A5;
}

button.accordion-button {
  border-radius: 19px !important;
}

.accordion-item {
  border: none;
  background-color: #ECECEC;
  margin-bottom: 10px;
  border-radius: 19px;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.plusCercle.pCerclePadding {
  margin-right: 25px;
}

ul.accourdionCourseBullot {
  padding-left: 50px;
}

.coreModuleRight {
  background: #ECECEC;
  border-radius: 30px;
  padding: 30px;
}

.coreModuleRight h1 {
  font-size: 20px;
}

.coreModuleRight p {
  font-size: 13px;
}

.coreModuleRight p a {
  color: #2A38FF;
}

h1.coreModuleH {
  color: #3D55A5;
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.space {
  height: 60px;
}

.outcomeSection {
  padding: 50px 0px;
}

.outcomeContent h1 {
  color: #3D55A5;
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.outcomeContent li {
  font-size: 20px;
  margin-bottom: 10px;
}

.pcourse .fa {
  color: #FFA845;
  margin-right: 10px;
}

.courseDimg {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  overflow: hidden;
}

.courseDimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.programsContentInfo1 {
  display: flex;
  color: #fff;
  align-items: center;
  gap: 20px;
}

.programsContentInfo1 h5 {
  color: #fff;
  font-family: 'Lato';
  font-size: 17px;
  margin: 0;
}

/* Search Bar Css Start */

form.searchForm input[type="search"] {
  width: 100%;
  padding: 7px;
  color: #000;
  border: none;
  border-radius: 20px;
}

form.searchForm {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

form.searchForm button {
  background: #3D55A5;
  border: none;
  color: #fff;
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.seachBox {
  z-index: 100;
  width: 100%;
  height: auto;
  padding: 40px 0px;
  background: #ECECEC;
  position: absolute;
  top: -100%;
  transition: top 0.5s;
}

.closeSearch {
  position: absolute;
  right: 70px;
  top: 27px;
  transform: rotate(45deg);
  font-size: 40px;
  font-weight: 300;
  cursor: pointer;
}

/* online counselling */
.headerImagecoun {
  background-image: url(./img/1637330549549.jpg);
  top: 0;
  left: 0;
  padding-top: 20%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 490px;
  overflow: hidden;
  position: relative;
}

.headerImagecoun2 {
  background-image: url("./img/bg2.jpg") !important;
  background-position: top;
}

.headerImagecoun1 {
  background-image: url("./img/how-career-counselors-can-help-you-find-your-calling-4.png") !important;
  background-position: top;
}

.bannerHeadingcoun {

  height: 100%;
  align-items: flex-end;
}

.futureBox {
  display: flex;
  align-items: center;
  height: 100%;
}

.bannerHeadingcoun h1 {
  color: #fff;
  font-size: 60px;
  font-family: "Arial", sans-serif;
  margin-top: 0px;
}

.backgroundcoun {
  padding: 0px 0px 20px;
  background: linear-gradient(1deg, black, black, #0000, #fff0, #fff0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.onlinecousectin {
  background-color: #ECECEC !important;
  border-bottom-right-radius: 30px;
  padding: 67px 0px !important;

}

.futurecolor h1 {
  text-align: right;
  color: #FFA845;
  margin: 0;
  font-size: 50px;
}

.programTitle1 h1 {
  font-size: 25px;
}

.programTitle1 {
  background: #3D55A5;
  padding: 9px 30px 9px 30px;
  color: #fff;
  border-radius: 69px;
  box-shadow: aliceblue;
  border: 1px solid #3D55A5;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%);
  background-position: calc(100% - 21px) calc(1em + -2px), calc(100% - 15px) calc(1em + -2px), 100% 0;
  background-size: 7px 7px, 7px 9px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.programTitle1 option {
  font-size: 16px;
  background: #3D55A5;
  padding: 6px 30px 6px 30px;
  color: #fff;
  border-radius: 19px;
  box-shadow: aliceblue;
  border: 1px solid #3D55A5;
  ;
  margin: 0;
  text-align: center;
  color: #fff !important;

}

.border1 {
  border: 3px solid #ECECEC;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.onlinecousectinp {
  padding: 39px 30px 20px 30px !important;
}

.loccss h3 {
  text-align: center !important;
  font-size: 20px;
  font-family: 'Lato';
}

.loccss {
  text-align: center !important;
}

.programTitle2 {
  background: #3D55A5;
  padding: 10px;
  border-radius: 19px;
  box-shadow: 0px 3px 6px #FFA845;
  width: 90%;
}

.programTitle1 h1 {
  font-size: 18px;
}

.programTitle2 {
  background: #FFA845;
  padding: 4px 0px;
  border-radius: 19px;
  border: 1px solid #ffa845;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #3C3C3C 50%), linear-gradient(135deg, #3C3C3C 50%, transparent 50%);
  background-position: calc(100% - 21px) calc(1em + -2px), calc(100% - 15px) calc(1em + -2px), 100% 0;
  background-size: 7px 7px, 7px 9px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.programTitle2 option {
  font-size: 16px;
  margin: 0;
  text-align: center;
  color: #000;

}

.programsSection1 {
  padding: 20px 0px 0px 0px;
}


.onlinecousectinstu {
  background-color: #ECECEC !important;
  border-radius: 30px;
  padding: 45px !important;
  height: 100%;
}

.onlinecousectinstu1 {
  background-color: #ECECEC !important;
  border-radius: 30px;
  padding: 45px !important;
  height: 100%;
}

.onlinecousectinstu img {
  width: 124px !important;
  text-align: center !important;
}

.onlinecousectinstu1 img {
  width: 89px !important;
  text-align: center !important;
}

.onlinecousectinstu h3 {
  color: #3D55A5 !important;
  padding: 20px;
  text-align: center !important;
  padding-bottom: 0;
  margin: 0;
}

.onlinecousectinstu1 h3 {
  color: #3D55A5 !important;
  padding: 20px;
  text-align: center !important;
  padding-bottom: 0px;
  margin: 0;
}


.counpro h6 {
  text-align: center;
}

.counpro p {
  text-align: center;
  font-size: 16px;
}

.counpro a {
  color: #3D55A5;
  text-align: center;
  text-decoration: underline;
}

.counpro1 h3 {
  text-align: center;
  color: #3D55A5;
  font-weight: 800;
  font-family: 'Lato';
  margin-bottom: 20px;
}

.counpro1 p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 60px;
  color: #3C3C3C;
}

.counpro1 a {
  color: #3D55A5 !important;
  text-align: center !important;
  ;
}

.pp p {
  padding-left: 25px;
  padding-right: 25px;
  text-align: left;
  margin-bottom: 47px;
}

.programBox1 {
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px;
  background-color: #ECECEC;
}

.pp a {
  color: blue;
}

/* Pathfinder quiz */
.pathFinder {
  padding: 50px 0px;
}

h1.quizTitle {
  font-size: 45px;
  font-family: 'Lato';
  color: #3D55A5;
  margin-bottom: 30px;
  font-weight: 800;
}

.quizHeader {
  background: #3D55A5;
  width: 100%;
  height: 80px;
  border-radius: 19px;
}

.pathFinderQuizBody {
  background: #ECECEC;
  height: 100%;
  border-radius: 30px;
  padding-bottom: 30px;
}

.quizPagination {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

span.pagiBullote {
  width: 13px;
  height: 13px;
  background: #979797;
  display: block;
  border-radius: 10px;
}

span.pagiBullote.active {
  background: #3C3C3C;
}

.quizNum {
  text-align: center;
}

.quizContent {
  width: 100%;
  height: 100vh;
}

.sideBarSearchProgram {
  margin-top: 20px;
}

.prorfolio {
  display: flex;
  gap: 50%;
  font-size: 18px;
  list-style: none;
}

.pad {
  padding: 9% !important;
}

.copyright1 {
  margin-top: 2%;
}

.loginborder {
  padding: 1%;
  border-top: 1px solid #cccccc8a;
}

body {
  overflow-x: hidden;
}

ul.tandcLink1 {

  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 2%;
}

ul.tandcLink1 a {
  list-style: none;
  color: #000;

  margin-top: 3%;
}

.copyright1 p {
  color: #000;
  margin: 0;
  font-size: 14px;
  margin-top: 2%;

}

ul.tandcLink2 {

  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

}

ul.tandcLink2 a {
  list-style: none;
  color: #000;


}

.copyright2 p {
  color: #000;
  margin: 0;
  font-size: 14px;

}

.css-187mznn-MuiSlider-root {
  width: 95% !important;
}

.nav-item a {
  padding-left: 0px;
  font-size: 16px;
  line-height: 120%;
  position: relative;
  background-color: #3D55A5 !important;
  /* margin: 0 0 10px 0; */
  color: #fff;
}

/*.nav-item a:before {
  content: '+';
  position: absolute;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  left: 8px;
  padding: 0 4px;
  font-weight: bold;
  top: 10px;
  line-height: 100%;
}*/
.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin-bottom: 0 !important;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  margin:0 2px;
}

.menu_bar {
  border-radius: 50%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
  border-radius: 50%;
}

/* .hamburger:hover {
  background-color: #2642af;
} */
.wrappers {
  position: relative;
  max-width: 390px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
  margin: 0 auto;
}

.wrappers h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #090909;
  text-align: center;
  margin-bottom: 30px;
}

.wrappers h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -13px;
  height: 1px;
  width: 50%;
  background: #3D55A5;
  transform: translateX(-50%);
}

.wrappers form {
  margin-top: 25px;
}

.wrappers .form-group {
  margin-bottom: 10px;
}

.wrappers form .input-box {
  margin: 8px 0;
  text-align: center;
}

.wrappers form input#submitButton1 {
  background: #3D55A5;
  border: 1px solid #3D55A5;
  color: #fff;
  text-transform: lowercase;
  padding: 4px 95px;
  border-radius: 20px;
  font-size: 18px;
}

.wrappers form input#submitButton2 {
  background: #fff;
  border: 1px solid #3D55A5;
  color: #3D55A5;
  text-transform: lowercase;
  padding: 4px 95px;
  border-radius: 20px;
  font-size: 18px;
}

.h11 {
  display: flex !important;
  align-items: center !important;
  margin: 0;
}

.h11:before {
  margin: 0 20px 0 0 !important
}

.h11:after {
  margin: 0 0px 0 20px !important
}

.h11:before,
.h11:after {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  background: #a39d9d !important;
}

.countryFlag {
  text-align: center;
  margin-top: 40px;
}

.countryFlag img {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  object-fit: cover;
  object-position: left;
}

.main-block {
  padding: 16px;
  max-width: 900px;
  margin: 0 auto;
  font-size: 18px;
}

.fixedTop {
  width: 100%;
  position: fixed !important;
  top: 0;
  z-index: 9999;
  box-shadow: 0 0 1px 1px #a9a3a3;
}

.fixedTop+.main-block {
  padding-top: 102px;
}

.navbar {
  padding: 0 15px;
}

.nav-pills .nav-link {
  padding: 10px 0 1px 19px !important;
  position: relative;
}

.nav-pills .nav-link:before {
  content: "+";
  position: absolute;
  left: 15px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #fff;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  text-align: center;
  top: 10px;
}

.nav-pills .nav-link.active:before {
  content: "-";
}

@media(max-width:991px) {
  .navigation-menu {
    display: none;
  }

  .navigation-menu.expanded {
    display: block;
  }

  .slider .searchPanel {
    width: 100%;
  }

  .slider {
    min-height: 250px;
  }

  .slider .searchPanel {
    top: 60px;
  }

  .hamburger {
    display: block;
  }

  .login_btn {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    /* border-top: 1px solid black; */
    box-shadow: -1px -1px 1px rgba(0, 0, 0, .1), 1px 1px 5px rgba(0, 0, 0, .2);
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    /* padding: 1.5rem 0; */
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .subscirpSection {
    width: 100%;
  }
}

@media(max-width:767px) {
  .slider .searchPanel {
    top: 20px;
  }

  .slider .searchPanel {
    left: 0 !important;
    margin-left: 0 !important;
  }

  .slider .searchPanel h1 {
    top: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 14px !important;
  }

  .slider {
    min-height: 160px !important;
  }

}

li.location {
  margin-top: 40px !important;
}


li.location {
  margin-top: 30px !important;
  text-align: right;

}

.scrollview .scrollview1 {
  height: 150px;
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 20px;
}

.scrollview .scrollview2 {
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 20px;
}

/* width */
.scrollview::-webkit-scrollbar p {
  width: 10px;
}

/* Track */
.scrollview::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollview::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollview1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollview2::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollview2::-webkit-scrollbar {
  width: 3px;
}

.scrollview1::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.scrollview2::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.scrollview1::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollview1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Track */
.scrollview2::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.scrollview2::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollview2::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.headerImageuesr {
  background-image: url(../img/userback.png);
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Finishing CSS Start */
.wrappers.py-5.p_position {
  bottom: -56px;
}

.daysAndDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsArrow {
  display: block;
  line-height: 0;
  margin-top: 4px;
}

.jobList {
  display: flex;
  justify-content: space-between;
}

h4.gName {
  font-size: 18px;
  margin-bottom: 5px;
}

.groupFollowersjob {
  font-size: 20px !important;
  font-family: 'Lato';
}

/* Finishing CSS End */

.rowpadingsp {

  margin-top: 20px;
}
h6.jobsearch {
  font-size: 20px;
  color: #454545 !important;
  font-weight: 700;
}



.groupFollowersjob {
  color: #3D55A5;
  font-weight: 700;
  font-size: 16px;
}

h4.gName {
  color: #3C3C3C;
  font-weight: 600;
}

.groupIcon {
  width: 40%;
  border-radius: 1px;
  min-height: 70px;
}

.groupName p {
  font-size: 12px;
}

.gallery-item-caption p span {
  margin-top: 15px;
}

.gallery-item-caption h2 {
  font-size: 20px;
}

body {
  font-family: 'Overpass', sans-serif;
}

.section-header {
  text-align: center;
  margin: 60px auto 20px auto;

  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  color: #222;
}

.section-header-underline {
  border: 1px solid #222;
  width: 3rem;
  margin: 0 auto;
  margin-bottom: 30px;
}

.video-gallery {
  position: relative;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 1000px;
  text-align: center;
}


.video-gallery .gallery-item {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 5px 1% 0px;
  min-width: 100%;
  max-width: 580px;

  border-radius: 5px;
  width: 48%;
  background: #000;
  cursor: pointer;
}

.video-gallery .gallery-item img {
  position: relative;
  display: block;
  opacity: .45;
  width: 105%;
  max-height: 100%;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-23px, 0, 0);
  backface-visibility: hidden;
}

.video-gallery .gallery-item .gallery-item-caption {
  padding: 17px 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
}

.video-gallery .gallery-item .gallery-item-caption,
.video-gallery .gallery-item .gallery-item-caption>a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-gallery .gallery-item h2 {
  font-weight: 300;
  overflow: hidden;
  padding: 0.5em 0;
}


.video-gallery .gallery-item h2,
.video-gallery .gallery-item p {
  position: relative;
  margin: 0;
  z-index: 10;
  color: #fff;
}

.video-gallery .gallery-item p {
  letter-spacing: 1px;
  font-size: 12px;
  /* padding: 1em 0; */
  opacity: 0;
  opacity: 1;

}

.video-gallery .gallery-item:hover img {
  opacity: .3;
  transform: translate3d(0, 0, 0);

}

.video-gallery .gallery-item .gallery-item-caption {
  text-align: left;
}







@media screen and (max-width: 50em) {
  .video-gallery .gallery-item {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
  }
}

.h6,
h6 {
  font-size: 22px;
  font-weight: bold;
}

.sectontop {
  margin-top: 10px;
}

.background1 {
  padding-top: 190px !important;
  background: linear-gradient(1deg, black, black, #fff0, #fff0);
}

.radioBoxDiv {
  margin-top: 10px;
}

.bannerHeadingjob h1 {
  font-size: 60px !important;
  font-family: 'Lato' !important;
  font-weight: 700 !important;
  margin-bottom: 30px !important;
}

.programTitleuser {
  background: #3D55A5;
  padding: 10px;
  /* padding: 2px 0px 10px 0px; */

  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.rowpadingsp {
  margin-top: 10px !important;
}

.programTitleuser h1 {
  font-size: 14px !important;
  margin: 0 !important;
  margin-left: 8px !important;
  color: #fff !important;
  font-family: 'Lato', sans-serif !important;
}

.rowpadresponshive p {
  font-weight: 700 !important;
}

.programTitleuser p {
  font-size: 10px !important;
  margin-left: 8px !important;
  color: #fff !important;
  font-weight: 100 !important;
}

h1.heding1 {
  color: #3D55A5;
  text-align: left;
  font-size: 40px;
}

.trendingButton1 button {
  border: none;
  background: #3C3C3C;
  color: #fff;
  padding: 5px 10px;
  border-radius: 27px;
  font-size: 12px;
  margin: 4px;
  margin-bottom: 10px;
}

h6.h6css {
  font-weight: 700;
}

.content .swiper-avatar {
  width: 100%;
  max-width: 90%;
  height: auto;
}

.content .swiper-avatar img {
  border-radius: 10px;
}

.content .cite {
  font-size: 14px;
  font-weight: bold;
}

.swiper .swiper-slide {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
  opacity: 0.5;
  background: rgba(255, 255, 255, .3);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}

/* .swiper .swiper-slide.swiper-slide-active {
   background:#cccccc;
   opacity: 1;
   border-radius: 15px !important;
   transform: scale(1.1);
} */
.swiper .swiper-nav-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: auto;
  padding-top: 3em;
}

.swiper .swiper-nav-wrapper .swiper-button-next,
.swiper .swiper-nav-wrapper .swiper-button-prev {
  top: 0;
  top: auto;
  left: auto;
  right: auto;
  position: relative !important;
}

.swiper .swiper-nav-wrapper .swiper-button-next:after,
.swiper .swiper-nav-wrapper .swiper-button-prev:after {
  display: none;
}

.swiper .swiper-nav-wrapper .swiper-button-next,
.swiper .swiper-nav-wrapper .swiper-container-rtl .swiper-button-prev {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5aBdBPk0VhfWyw9-Cs1uVnsSACLYwyKkYKGYuoX_u6A&s") !important;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.swiper .swiper-nav-wrapper .swiper-button-prev,
.swiper .swiper-nav-wrapper .swiper-container-rtl .swiper-button-next {
  background-image: url("https://icon2.cleanpng.com/20180603/usw/kisspng-less-than-sign-greater-than-sign-computer-icons-eq-5b144d758181f9.9344505015280572055305.jpg") !important;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.swiper-pagination {
  margin: 0;
  padding: 0;
  width: auto;
  position: relative !important;
  display: block;
  width: auto;
}

.swiper-pagination .swiper-pagination-bullets {
  margin: 0;
}

.swiper-pagination .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
  background: #2a3c76 !important
}

.swiper-pagination-bullet {
  background: #2a3c76 !important;
  transition: all 0.2s ease-in-out;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  bottom: 0;
  width: auto;
  padding: 0 1.5em;
}

.swiper-pagination-bullet-active {
  transform: scale(1.5);
}

p.para {
  font-size: 14px !important;
}

.container1>div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f1f2f9;
  direction: rtl;
}

/* .slider {
   width: 1000px;
          margin-left: -89px;
  

} */
.container1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-template-rows: 200px 200px;
  gap: 15px;
  grid-auto-flow: dense;
}

.overlay {
  position: absolute;
  z-index: 100;
  width: 100%;
  min-height: 100px;
  max-height: auto;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.87));
}

.overlay h3 {
  padding: 20px;
  padding-bottom: 0;
  color: #fff;
}

.swiper .content-wrapper .content .swiper-avatar {
  width: 100%;
  max-width: 90%;
  height: auto;
}

.content {
  padding: 3em 1.5em;
  text-align: center;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  color: #000;
  background-color: #ccc;
}

.swiper .swiper-slide {
  /* margin: 0; */
  /* height: auto; */
  width: 100%;
  padding: 0;
  opacity: 0.5;
  /*
   background: rgba(255, 255, 255, .3); */
  /* border-radius: 6px; */
  /* transition: all 0.5s ease-in-out; */
}

.swiper .content-wrapper .content .swiper-avatar {
  width: 100%;
  max-width: 90%;
  height: auto;
}

.content .cite {
  font-size: 14px;
  font-weight: bold;
}

.img1 {
  width: 100%;
  height: auto;
}

.swiper-wrapper {
  display: flex;
}

body .wrapper,
html .wrapper {
  /* width: 100%; */
  /* max-width: 1200px; */
  margin: 0 auto;
  text-align: center;
  color: #fff;
  padding: 1.5em;
  margin: 50px 0px;
}

.swiper {
  margin: 10px 123px;
  height: auto;
}

.content .cite {
  font-size: 20px;
  margin: 3px 0px;
  text-transform: uppercase;
  font-weight: bold;
  color: #444040;
  margin-bottom: 20px;
  padding: 20px;
  letter-spacing: 4px;
  border-bottom: 2px solid #d17605;
}

h1.heding2 {
  color: #3D55A5;
  text-align: center;
  font-size: 50px;
}

p.paragraph {
  text-align: center;
  color: #3C3C3C;
  font-weight: 600;
}

a.btn.btn-square2.text-white.me-2 {
  width: 45%;
  height: 40px;
  border-radius: 20px;
  background-color: #2a3c76;
  color: #fff;
  font-weight: 500 !important;
  padding: 7px;
  font-size: 16px !important;
  text-transform: lowercase;
}

.row.pad2 {
  margin-top: 70px;
}

.swiper .swiper-nav-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 17%;
  padding-top: 3em;
}

.swiper-slide.swiper-slide-active {
  /* padding: 4em 1.5em; */
  transform: scale(1.1);
  opacity: 0.9;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.HeroBanner {
  padding: 20px 0px;
}

span.swiper-pagination-bullet {
  margin-top: 70px !important;
}

.bg-white1 {
  --bs-bg-opacity: 1;
  box-shadow: 0 0 1px 1px #a9a3a3;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

hr {

  color: inherit;
  border: 0;
  margin: 5px !important;
  border-top: 1px solid;
  opacity: .25;
  color: #fff !important;
}

.programTitleuser p {
  margin: 2px;
}

.followUs1 {

  text-align: left;
  float: left !important;
}

.footerTitle1 h3 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-weight: bold;

}

.footerTitle1 {
  margin-bottom: 27px;
}

a.socialLinkItem1 i .spam {
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
}

.css-igs3ac {
  border: none !important;
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.css-1k33q06 {
  user-select: none;
  color: #fff !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  color: #fff !important;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

p.paragraph1 {
  width: 400px;
}


/* job section start css */
.jobSection {
  background: #ececec;
  border-radius: 30px;
  margin-top: 20px;
}

.programTitle {
  background: #3D55A5;
  padding: 15px;
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
}

h1.htext {
  font-size: 35px;
  font-weight: 800;
}

.dropdown {
  background-image: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
  background-repeat: no-repeat;
  background-position: right center;
}

.jobinpute {
  margin-bottom: 8px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
  color: #000;
  font-size: 14px !important;
}

p.ptext {
  font-size: 27px;
  color: #fff;
}

i.fa.fa-map-marker {
  font-size: 25px;

  color: #FFA845;
  margin: 3px;
}

.jobcontent {
  padding: 30px;
}

.jobTitle h1 {
  font-size: 26px;
  font-weight: 800;
  color: #323232;
}

.space30 {
  height: 30px;
}

.jobbutton1 {
  position: absolute;
  right: 246px;
  border: none;
  background: #bbbbbb;
  color: #0e0e0e;
  border-radius: 26px;
  padding: 1px 20px;
  font-family: 'Lato';
}

.jobbutton {
  position: absolute;
  right: 166px;
  border: none;
  background: #3D55A5;
  color: #fff;
  border-radius: 26px;
  padding: 1px 15px;
  font-family: 'Lato';
}

.jobb {
  position: absolute;
  border: none;
  background: #bbbbbb;
  color: #0e0e0e;
  border-radius: 26px;
  padding: 1px 20px;
  font-family: 'Lato';
  margin-top: -12px;
}

.space30 {
  height: 30px;
}

.jobcontent11 {
  margin-left: 30px;
  margin-top: -30px;
}

label {
  display: inline-block;

}

h1.htext {
  font-size: 35px !important;
  font-weight: 800 !important;
}

label {
  display: inline-block;

}

.programTitlejob {
  background: #3D55A5;
  padding: 15px;
  border-radius: 19px;
  box-shadow: 0px 3px 6px #00000029;
}

.jobbutton1 {
  position: absolute;
  right: 246px;
  border: none;
  background: #3d55a5;
  color: #0e0e0e;
  border-radius: 26px;
  color: #fff;
  padding: 8px 30px;
  font-family: 'Lato';
}

.table-wrapper {

  margin: 30px auto !important;
  background: #fff !important;
  padding: 20px !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05) !important;
}

.table-title {
  padding-bottom: 10px !important;
  margin: 0 0 10px !important;
}

.table-title h2 {
  margin: 6px 0 0 !important;
  font-size: 22px !important;
}

.table-title .add-new {
  float: right !important;
  height: 30px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  text-shadow: none !important;
  min-width: 100px !important;
  border-radius: 50px !important;
  line-height: 13px !important;
}

.table-title .add-new i {
  margin-right: 4px !important;
}

table.table {
  table-layout: auto !important;
}

table.table tr th,
table.table tr td {
  border-color: #e9e9e9 !important;
}

table.table {
  table-layout: auto;
}

.table-bordered {
  border: 1px solid #ddd !important;
}

table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}

table.table th:last-child {
  width: 100px;
}

table.table td a {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  min-width: 24px;
}

table.table td a.add {
  color: #27C46B;
}

table.table td a.edit {
  color: #FFC107;
}

table.table td a.delete {
  color: #E34724;
}

table.table td i {
  font-size: 19px;
}

table.table td a.add i {
  font-size: 24px;
  margin-right: -1px;
  position: relative;
  top: 3px;
}

table.table .form-control {
  height: 32px;
  line-height: 32px;
  box-shadow: none;
  border-radius: 2px;
}

table.table .form-control.error {
  border-color: #f50000;
}

table.table td .add {
  display: none;
}

th,
tr {
  border: 1px solid black !important;
}

table.table.table-bordered tr td {
  border: 1px solid #3d55a5 !important;
}

table.table.table-bordered tr th {
  border: 1px solid #3d55a5 !important;
}


/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");

div#sidebarCollapse1 {
  background-color: #fff;
  padding: 10px;
  width: 220px;
  height: 100%;
}

li.nav-item1 {
  padding: 10px;
}

.card.shadow.border-0.mb-7 {
  background-color: #fff !important;
  color: #000 !important;
}

.table td {
  font-size: .8125rem;
  white-space: normal !important;
}

@media only screen and (max-width: 767px) {
  .wrappers.py-5.p_position {
    bottom: 0px !important;

  }

  .wrappers {
    border-radius: 19px;
    position: relative;
    margin-bottom: 30px;
  }

  .programTitleuser {
    margin-bottom: 15px;
  }

  .rowpadingsp {
    margin-top: 0px !important;
  }

  .video-gallery {
    margin: 20px auto 0;
  }

  .programsSection {
    margin-top: 0px !important;
  }

  .sideBarFooter {
    display: flex;
    gap: 5px;
    margin-top: 20px;
  }

  .commentbox {
    padding-bottom: 0px;
  }

  .commentbox {
    padding: 0px;
  }

  .loadBtn {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: center;
  }

  .banneerSection {
    margin-top: 37px !important;
  }

  footer.footerSection {
    background-image: linear-gradient(0deg, #ececec, #ececec, #ececec03);
    margin-top: 0px;
    padding: 80px 0 20px;
  }

  .background1 {
    padding-top: 30px !important;
    background: linear-gradient(-197deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4), #fff0, #fff0);
  }




  .wrappers {
    border-radius: 19px;
    position: relative;
    margin-bottom: 30px;
  }

  .programTitleuser {
    margin-bottom: 15px;
  }

  .rowpadingsp {
    margin-top: 0px !important;
  }

  .video-gallery {
    margin: 20px auto 0;
  }

  .programsSection {
    margin-top: 0px !important;
  }

  .sideBarFooter {
    display: flex;
    gap: 5px;
    margin-top: 20px;
  }

  .commentbox {
    padding-bottom: 0px !important;
  }

  .commentbox {
    padding: 0px !important;
  }

  .loadBtn {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: center;
  }

  .banneerSection {
    margin-top: 37px !important;
  }

  footer.footerSection {
    background-image: linear-gradient(0deg, #ececec, #ececec, #ececec03);
    margin-top: 0px;
    padding: 80px 0 20px;
  }

  .background1 {
    padding-top: 30px !important;
  }


  .footer.footerSection {
    margin-top: 0px;
    padding: 30px 0 20px;
  }

  .sideBarFooter {
    padding-bottom: 15px;
  }

  .subscirpSection {
    width: 100%;
  }

  ul.tandcLink {
    justify-content: left;
  }

  .banneerSection {
    margin-top: 15px !important;
  }

  .wrappers {
    border-radius: 19px;
    position: absolute;
  }

  .background.mobileCssBG {
    height: 100%;
    padding-top: 350px;
  }

  .bannerHeading h1 {
    font-size: 30px;
  }

  .headerImage {
    height: 480px;
    background-position: -240px 0px;
  }

  .pulsating {
    height: 156px;
    border-top-left-radius: 45px !important;
    border-bottom-left-radius: 45px !important;
    width: 28px;
  }

  .programsSideBar1 {
    margin-top: 0px !important;
  }

  .programsSideBar {
    margin-top: 271px;
  }

  .call-fixed-button1 {
    right: -13px;
    top: 490px;
  }

  .pulsating p {
    padding: 0;
  }

  .onlinecousectinstu1 {
    margin-top: 22px !important;
    ;
  }

  .programsSection3 {
    margin-top: 20px !important;
    ;
  }
}


@media (min-width: 767px) and (max-width:992px) {

  .ps-0 {
    padding: 0px !important;
  }

  .wrappers.py-5.p_position {
    bottom: 40px !important;
  }

  .video-gallery {
    margin: 38px auto 0;
  }


  .sideBarFooter {
    margin-top: 20px;
  }

  .commentbox {
    margin: 8px 0;
    padding: 24px 0px 0px 0px !important;
  }

  .loadBtn {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: center;
  }

  .banneerSection {
    margin-top: 30px !important;
  }
}

.slider .searchPanel .btnSearch {
  font-size: 15px !important;
  font-weight: 500;
  padding: 10px 15px;
  z-index: 0;
}

.searchPanel h2 {
  font-size: 1.8rem !important;
}

.nav-item a {
  border-radius: 0.375rem;
  padding: 0px;
}

.tab_sidebar .flex-column.rounded.nav {
  gap: 10px;
}

.leftSideBtn {
  padding: 10px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  width: auto !important;
  min-width: auto !important;
}
.swiper-button-next::after, .swiper-button-prev::after{
  display:none;
}
.login_btn{
  padding:7px 25px;
  border:0!important;
}
.copyright{
  padding:25px 0 0 0;
}
.footerBody ul li a{
  font-size:14px;
}
.footerBody ul li a:hover{
  color:#3d55a5!important;
  text-decoration: underline!important;
}
.text-left{
  text-align:left;
  font-size: 14px;
}
.text-right{
  text-align: right;
}
.footerBottom a{
  padding:5px 10px;
}
.color-primary{
  color:#3D55A5!important;
}

.solialLink li .fa{
  font-size: 20px;;
}
a,.footerBody ul li a{
  font-family: 'Roboto', sans-serif !important;
}
.mainHeader{
  padding:0!important;
}
.dropdown button{
  padding:0!important;
}
#dropdown-basic {
  width: 50px!important;
}